<template lang="pug">
  BInputGroup
    BFormInput.password-input(
      :class="{ invalid }"
      :type="typeInput"
      v-bind="$props"
      v-on="$listeners"
    )
    template(v-slot:append)
      FaIcon.password-input-icon(
        :icon="eyeIcon"
        @click="handleClick"
      )
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        required: true
      },
      placeholder: {
        type: String,
        default: "********"
      },
      name: {
        type: String,
        default: "password"
      },
      invalid: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        eyeIcon: "eye-slash",
        typeInput: "password"
      }
    },

    methods: {
      handleClick() {
        if (this.eyeIcon === "eye") {
          this.eyeIcon = "eye-slash"
          this.typeInput = "password"
        } else {
          this.eyeIcon = "eye"
          this.typeInput = "text"
        }
      }
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/variables.sass"
  // disable default eye icon for IE and Ms Edge browsers
  input[type=password]::-ms-reveal,
  input[type=password]::-ms-clear
    display: none

  .password-input
    border: 1px solid $default-purple
    border-radius: 3px !important
    padding-right: 20px

    &.invalid
      border: 1px solid $default-red

    &:focus
      box-shadow: none !important

    &-icon
      cursor: pointer
      height: 10px
      position: absolute
      right: 2px
      top: 15px
      width: 1.125em !important
      z-index: 3
</style>
